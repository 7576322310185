import { PRODUCT_CODES as pc } from '@/constants'

const config = {
    [`${pc.LOOM_AND_LEAF}`]: [
        {
            title: `Perfect balance of body-hugging feel and optimal support`,
            content: () => [
                'Hugs every curve while keeping your spine in healthy neutral alignment in any sleep position',
                'Multiple memory foam layers give a body-hugging feel while offering great edge support with no sagging'
            ],
            imageFolder: 'generic/bed-family',
            imageName: 'bed-family-1-1.jpg',
            imageAlt: 'Family sleeping comfortably on the memory foam mattress'
        },
        {
            title: `Relief for achy backs and joints`,
            content: () => [
                'Lumbar Zone® Quilting & gel-infused memory foam lumbar crown offers enhanced support where you need it most',
                'Pressure-relieving memory foams cradle your body for improved circulation and more restful sleep'
            ],
            imageFolder: 'generic/bed-couple',
            imageName: 'bed-couple-1-1.jpg',
            imageAlt: 'Couple on the memory foam mattress'
        },
        {
            title: `Chiropractor recommended`,
            content: () => [
                'Recommended by leading U.S. chiropractors',
                'Our patented Lumbar Zone® Technology was awarded a seal of approval from the Congress of Chiropractic State Associations',
                'Endorsed by the American Chiropractic Association'
            ],
            imageFolder: 'generic/woman-stretching',
            imageName: 'woman-stretching-1-1.jpg',
            imageAlt: 'Woman stretching her arms while sitting on the memory foam mattress'
        }
    ],
    [`${pc.SAATVA_CONTOUR5}`]: [
        {
            title: `Perfect balance of body-hugging feel and optimal support`,
            content: () => [
                'Provides the deep, body-hugging feel of a classic memory foam while keeping your spine in healthy neutral alignment',
                'Proprietary Layers of high-density foams and a thick support foam base prevent sagging'
            ],
            imageFolder: 'generic/bed-family',
            imageName: 'bed-family-1-1.jpg',
            imageAlt: 'Family sleeping comfortably on the memory foam mattress'
        },
        {
            title: `Relief for achy backs and joints`,
            content: () => [
                'Patented Lumbar Zone® PCM Matrix keeps the center third of the mattress cooler to allow for optimal support',
                'Prevents the hips and lumbar spine from sinking in too deeply while cradling pressure points for comfortable sleep'
            ],
            imageFolder: 'generic/highlights-lumbar',
            imageName: 'highlights-lumbar-1-1.jpg',
            imageAlt: 'Man laying on the memory foam mattress'
        },
        {
            title: `Chiropractor recommended`,
            content: () => [
                'Recommended by leading U.S. chiropractors and endorsed by the American Chiropractic Association',
                'Our patented Lumbar Zone® Technology was awarded a seal of approval from the Congress of Chiropractic State Associations'
            ],
            imageFolder: 'generic/woman-stretching',
            imageName: 'woman-stretching-1-1.jpg',
            imageAlt: 'Woman stretching her arms while sitting on the memory foam mattress'
        }
    ],
    [`${pc.ZENHAVEN}`]: [
        {
            title: `Cradles the body in weightless support`,
            content: () => [
                'Offers a unique balance of a buoyant feel and responsiveness',
                'Excellent pressure relief for achy backs and joints'
            ],
            imageFolder: 'generic/highlights-hybrid',
            imageName: 'highlights-hybrid-1-1.jpg',
            imageAlt: 'Woman laying comfortably on the Zenhaven mattress'
        },
        {
            title: `Cooling & hypoallergenic`,
            content: () => [
                'Layers of highly breathable natural materials for cool, comfortable sleep',
                'Naturally hypoallergenic and antimicrobial organic wool and natural latex are especially beneficial for allergy-prone sleepers'
            ],
            imageFolder: 'generic/highlight-cooling',
            imageName: 'highlight-cooling-1-1.jpg',
            imageAlt: 'Layers of breatheable materials on the Zenhaven mattress'
        },
        {
            title: `Chiropractor recommended`,
            content: () => [
                'Recommended by leading U.S. chiropractors and endorsed by the American Chiropractic Association',
                'Our patented Lumbar Zone® Technology was awarded a seal of approval from the Congress of Chiropractic State Associations'
            ],
            imageFolder: 'generic/woman-stretching',
            imageName: 'woman-stretching-1-1.jpg',
            imageAlt: 'Woman stretching her arms while sitting on the Zenhaven mattress'
        }
    ],
    [`${pc.SOLAIRE}`]: [
        {
            title: '50 precise firmness settings',
            content: () => [
                'Incrementally set your perfect firmness for each side with a wireless remote (uses three AAA batteries)',
                'Uses the most precise scientific standard of pressure, mm/Hg'
            ],
            imageFolder: 'products/solaire/remote-lifestyle',
            imageName: 'solaire-remote-lifestyle-1-1.jpg',
            imageAlt: 'Remote resting on a Solaire Mattress'
        },
        {
            title: 'Superior back support and pressure relief for effortless sleep',
            content: () => [
                'Ergonomically-zoned latex layer for pressure-free support',
                'Cushioned Euro pillow top and memory foam layers add even more pressure-relieving comfort'
            ],
            imageFolder: 'products/solaire/highlights-support',
            imageName: 'solaire-highlights-support-1-1.jpg',
            imageAlt: 'An overhead view of one corner of the Solaire mattress'
        },
        {
            title: 'Sleep cooler and more comfortably',
            content: () => [
                'Cover made with organic cotton and a natural latex comfort layer give you ultimate breathability',
                'Gel-infused memory foam disperses body heat away to keep you cooler'
            ],
            imageFolder: 'products/solaire/highlights-cooler',
            imageName: 'solaire-highlights-cooler-1-1.jpg',
            imageAlt: 'A close up photo of the top of the Solaire mattress'
        },
        {
            title: 'Discreet, streamlined components',
            content: () => [
                'Hidden in-rail inflator produces less noise',
                'No external pumps or hoses cluttering your bedroom'
            ],
            imageFolder: 'products/solaire/highlights-components',
            imageName: 'solaire-highlights-components-1-1.jpg',
            imageAlt: 'A cut-away photo of the internal components of the Solaire mattress'
        },
        {
            title: 'Individualized comfort',
            content: () => [
                'Separate air chambers give each sleeper control over their side\'s firmness',
                'Virtually eliminates all motion transfer'
            ],
            imageFolder: 'products/solaire/highlights-comfort',
            imageName: 'solaire-highlights-comfort-1-1.jpg',
            imageAlt: 'A photo of the air chambers in the Solaire mattress'
        }
    ],
    [`${pc.SAATVA_RX}`]: [
        {
            title: 'Designed for serious, chronic back & joint conditions',
            content: () => [
                'The first luxury mattress designed to help alleviate serious discomfort beyond normal achy joints & sore backs',
                'Advanced Therapeutic Support Core™ provides maximum body-contouring support for areas of chronic pain'
            ],
            imageFolder: 'generic/highlights-chiropractor-warm',
            imageName: 'highlights-chiropractor-warm-1-1.jpg',
            imageAlt: 'Woman stretching her arms above her head'
        },
        {
            title: 'Less motion transfer means more restorative sleep',
            content: () => [
                'Self adjusts to every movement more effectively than single-layer foams',
                'Absorbs motion so you get more undisturbed sleep'
            ],
            imageFolder: 'generic/highlights-less-motion',
            imageName: 'highlights-less-motion-1-1.jpg',
            imageAlt: 'Close up of a Saatva mattress'
        },
        {
            title: 'Chiropractor recommended',
            content: () => [
                'Recommended by leading U.S. chiropractors',
                'Our patented Lumbar Zone® Technology was awarded a seal of approval from the Congress of Chiropractic State Associations',
                'Endorsed by the American Chiropractic Association'
            ],
            imageFolder: 'generic/woman-stretching',
            imageName: 'woman-stretching-1-1.jpg',
            imageAlt: 'Woman stretching her arms above her head'
        }
    ],
    [`${pc.SAATVA_HD}`]: [
        {
            title: 'Superior support for every body',
            content: () => [
                'Multiple layers of comfort with a unique combination of natural latex, 5lb memory foam, denser support foam, and 12-gauge tempered steel coils',
                'Double the edge support of other luxury mattresses to make getting in and out of bed easier'
            ],
            imageFolder: 'generic/elders-jumping',
            imageName: 'elders-jumping-1-1.jpg',
            imageAlt: 'Elderly couple jumping on bed'
        },
        {
            title: 'Relief for achy backs and joints',
            content: () => [
                'Hand-tufted Euro pillow top cradles you in cushioned comfort to relieve pressure points',
                'Patented Lumbar Zone® Technology ensures healthy spinal alignment no matter how you sleep'
            ],
            imageFolder: 'generic/woman-stretching-2',
            imageName: 'woman-stretching-2-1-1.jpg',
            imageAlt: 'Woman stretching her arms above her head'
        },
        {
            title: 'Chiropractor recommended',
            content: () => [
                'Recommended by leading U.S. chiropractors and endorsed by the American Chiropractic Association',
                'Our patented Lumbar Zone® Technology was awarded a seal of approval from the Congress of Chiropractic State Associations'
            ],
            imageFolder: 'generic/bed-woman-3',
            imageName: 'bed-woman-3-1-1.jpg',
            imageAlt: 'Woman sitting on bed'
        }
    ],
    [`${pc.SAATVA_CLASSIC}`]: [
        {
            title: 'Relief for achy backs and joints',
            content: () => [
                'Plush Euro pillow top cradles you in cushioned comfort to relieve pressure points',
                'Proprietary Lumbar Zone® Technology in the center third of the mattress ensures healthy spinal alignment no matter how you sleep'
            ],
            imageFolder: 'generic/highlights-lumbar',
            imageName: 'highlights-lumbar-1-1.jpg',
            imageAlt: 'Highlights Lumbar Latex Hybrid'
        },
        {
            title: 'Responsive, durable support',
            content: () => [
                `Individually wrapped comfort coils respond to your body's curves and movements`,
                `Support coil base offers superior durability you just can't get in a bed-in-a-box`
            ],
            imageFolder: 'generic/highlights-support',
            imageName: 'highlights-support-1-1.jpg',
            imageAlt: 'Woman laying on bed'
        },
        {
            title: 'Chiropractor recommended',
            content: () => [
                'Recommended by leading U.S. chiropractors and endorsed by the American Chiropractic Association',
                'Our patented Lumbar Zone® Technology was awarded a seal of approval from the Congress of Chiropractic State Associations'
            ],
            imageFolder: 'generic/woman-stretching',
            imageName: 'woman-stretching-1-1.jpg',
            imageAlt: 'Woman stretching her arms above her head'
        }
    ],
    [`${pc.MEMORY_FOAM_HYBRID}`]: [
        {
            title: 'The best of memory foam & innerspring in one',
            content: () => [
                'Deep, body-contouring feel and pressure relief of a memory foam',
                'Responsive feel and great ease of movement of an innerspring',
                'Perfect for back, combination, and side sleepers'
            ],
            imageFolder: 'generic/highlights-hybrid',
            imageName: 'highlights-hybrid-1-1.jpg',
            imageAlt: 'Woman sleeping'
        },
        {
            title: 'Cooler sleep',
            content: () => [
                'Actively draws heat away from the body and disperses it to maintain a comfortable temperature',
                'Innerspring coils allow for great airflow throughout the mattress'
            ],
            imageFolder: 'generic/highlights-cooler',
            imageName: 'highlights-cooler-1-1.jpg',
            imageAlt: 'Woman laying on bed'
        },
        {
            title: 'Healthier for you & the planet',
            content: () => [
                'Hypoallergenic organic cotton cover treated with with Guardin™ botanical antimicrobial treatment to inhibit the growth of bacteria, mold, and mildew',
                'CertiPUR-US® certified foams, free of potentially harmful chemicals'
            ],
            imageFolder: 'generic/highlights-healthier',
            imageName: 'highlights-healthier-1-1.jpg',
            imageAlt: 'Closer look of Memory Foan Fabric'
        }
    ],
    [`${pc.SAATVA_LATEX_HYBRID}`]: [
        {
            title: 'Uplifting support & breathable comfort',
            content: () => [
                'Responsive organic natural latex gives a uniquely weightless feel for unmatched pressure relief',
                'Combination of ultra-breathable natural latex and individually wrapped coils creates a superbly breathable mattress for those who sleep hot'
            ],
            imageFolder: 'generic/highlight-chiropractor',
            imageName: 'highlight-chiropractor-1-1.jpg',
            imageAlt: 'Woman reaching on edge of bed.'
        },
        {
            title: 'Durable materials for years of great sleep',
            content: () => [
                'Natural latex provides years of consistent, durable support that outlasts any synthetic foams',
                'Individually wrapped coils made with tempered recycled steel and enhanced durability in the lumbar region where most of your body weight sits.'
            ],
            imageFolder: 'generic/highlights-materials',
            imageName: 'highlights-materials-1-1.jpg',
            imageAlt: 'Durable Latex Hybrid Material'
        },
        {
            title: 'Chiropractor recommended',
            content: () => [
                'Recommended by leading U.S. chiropractors and endorsed by the American Chiropractic Association',
                'Our patented Lumbar Zone® Technology was awarded a seal of approval from the Congress of Chiropractic State Associations'
            ],
            imageFolder: 'generic/woman-stretching',
            imageName: 'woman-stretching-1-1.jpg',
            imageAlt: 'Woman stretching her arms while sitting on the memory foam mattress'
        }
    ]
}

export { config }
